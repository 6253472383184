import {LaCon} from 'constants/Links';
/**
* @param {string} link rest of link to api
* @param {function} success function to run when everything goes well 
* @param {function} error funciton to run on error
* @param {var} input in case its a POST
*/
export var LaConFetch = (link,success,error,input=null)=>{
    if(input){
        fetch(`${LaCon}${link}`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('client_key')}`,
                    'Accept': 'application/json'
                },
                body: JSON.stringify(input),
            })
        .then(res=>res.json())
        .then((result)=>{
            if(success){
                success(result);
            }
        },(err)=>{
            if(error){
                error(err)
            }
            console.error(err)
        });
    }else{
        fetch(`${LaCon}${link}`,{
            headers:{
                'Authorization': `Bearer ${sessionStorage.getItem('client_key')}`,
                'Accept': 'application/json'
            }
        })
        .then(res=>res.json())
        .then((result)=>{
            if(success){
                success(result);
            }
        },(err)=>{
            if(err){
                error(err);
            }
            console.error(err)
        });
    }
}